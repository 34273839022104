import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '@library';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    private router = inject(Router);
    private readonly windowService = inject(WindowService);

    xl$ = this.windowService.xl$;
    lg$ = this.windowService.lg$;
    md$ = this.windowService.md$;
    sm$ = this.windowService.sm$;
    xs$ = this.windowService.xs$;

    onLogoutClick() {
        this.router.navigate(['/logout'], {
            skipLocationChange: true,
        });
    }
}
