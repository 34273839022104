<div class="pl-4 mb-7">
    <button class="inline-flex items-center" type="button" (click)="onClick()">
        <div class="cart">
            <app-icon name="mdi:cart-outline" class="text-md text-gray-700 leading-4"></app-icon>
            <span @ShowHide *ngIf="cartProcessing$ | async" class="loader"></span>
        </div>

        <span class="text-sm leading-none text-gray-700 font-semibold whitespace-nowrap">{{ cart$ | async | total | price }}</span>
    </button>
</div>
