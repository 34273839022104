import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

@Injectable({ providedIn: 'root' })
export class BackButtonNativeService {
    init() {
        if (Capacitor.getPlatform() === 'android') {
            this.initAndroidBackButton();
        }
    }

    private initAndroidBackButton() {
        App.addListener('backButton', ({ canGoBack }) => this.goBack(canGoBack));
    }

    private goBack(canGoBack: boolean) {
        if (canGoBack === true) {
            window.history.back();
        } else {
            App.exitApp();
        }
    }
}
