<app-box type="logo">
    <ul>
        <li>
            <app-cart-summary />
        </li>

        @for (item of menuItems$ | async; track $index; let last = $last) {
            <li [class.last]="last">
                <a class="item" (click)="onItemClick(item)">
                    {{ item.name | translate }}
                </a>
            </li>
        }

        <li class="mt-auto">
            <a class="item" href="#" (click)="onProfileClick($event)" translate>btn.profile</a>
        </li>

        <li *ngIf="(pushNotificationsSupported$ | async) === true">
            <a class="item" href="#" (click)="onPushNotificationsClick($event)" translate>btn.push-notifications</a>
        </li>

        <li *ngIf="(userSwitchEnabled$ | async) === true">
            <a class="item" href="#" (click)="onUserSwitchClick($event)" translate>btn.switch-user</a>
        </li>

        <li *ngIf="(userSwitchEnabled$ | async) === false">
            <a class="item" href="#" (click)="onLogoutClick($event)" translate>btn.logout</a>
        </li>

        <li>
            <div class="px-6 pt-10 pb-10">
                <app-language-switch />
            </div>
        </li>
    </ul>
</app-box>
