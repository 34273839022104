<div class="box">
    <div class="header">
        @if (type === 'logo') {
            <div>
                <app-logo />
            </div>
        } @else {
            <!-- <button
                app-button
                type="button"
                [routerLink]="['..']"
                [content]="'btn.back' | translate"
                size="sm"
                color="gray-600"
                iconLeft="chevron-left"
                appearence="text"
            ></button> -->
        }

        <button type="button" class="text-[0] ml-auto mr-4" (click)="onCloseClick()">
            <app-icon class="text-[2rem] text-tertiary" name="material-symbols:close-rounded" />
        </button>
    </div>
    <div class="content">
        <ng-content />
    </div>
</div>
