import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { IconComponent } from '@library';
import { ShellSharedModule } from '../../shared/shell-shared.module';
import { LanguageSwitchComponent } from '@app/i18n';
import { RouterModule } from '@angular/router';

import { ShellAuthComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileMenuButtonComponent } from '@app/mobile-menu/shared/components';

@NgModule({
    imports: [CommonModule, IconComponent, ShellSharedModule, SharedModule, RouterModule, LanguageSwitchComponent, MobileMenuButtonComponent],
    declarations: [ShellAuthComponent, HeaderComponent],
})
export class ShellAuthModule {}
