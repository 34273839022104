import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { IconComponent } from '@library';
import { LanguageSwitchComponent } from '@app/i18n';
import { ShippingAddressesModule } from '@app/modules/shipping-addresses';
import { ShellSharedModule, FooterComponent } from '../../shared';
import { RouterModule } from '@angular/router';
import { ConcessionsModule } from '@app/modules/concessions';
import { StatusesContainerModule } from '@app/shared/components';
import { BlockadesModule } from '@app/modules/blockades';

import { ShellUserComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileMenuButtonComponent } from '@app/mobile-menu/shared/components';

@NgModule({
    imports: [
        CommonModule,
        IconComponent,
        SharedModule,
        ShippingAddressesModule,
        LanguageSwitchComponent,
        ShellSharedModule,
        RouterModule,
        ConcessionsModule,
        BlockadesModule,
        StatusesContainerModule,
        MobileMenuButtonComponent,
        FooterComponent,
    ],
    declarations: [ShellUserComponent, HeaderComponent],
})
export class ShellUserModule {}
