import { Component, inject } from '@angular/core';
import { WindowService } from '@library';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    private readonly windowService = inject(WindowService);

    readonly xl$ = this.windowService.xl$;
    readonly lg$ = this.windowService.lg$;
    readonly md$ = this.windowService.md$;
    readonly sm$ = this.windowService.sm$;
    readonly xs$ = this.windowService.xs$;
}
