import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '@library';
import { TranslateModule } from '@ngx-translate/core';
import { options } from '@options';

@Component({
    selector: 'app-footer-adresses',
    templateUrl: './footer-adresses.component.html',
    styleUrls: ['./footer-adresses.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent],
})
export class FooterAdressesComponent {
    readonly footer = options.footer;
    readonly logoSrc = options.logoSrc.white;
}
