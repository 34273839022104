<app-box type="logo">
    <ul>
        @for (item of menu; track $index; let last = $last) {
            <li>
                <a class="item" (click)="onItemClick(item)">
                    {{ item.name | translate }}
                </a>
            </li>
        }

        <li class="mt-auto">
            <div class="px-6 pt-10 pb-10">
                <app-language-switch />
            </div>
        </li>
    </ul>
</app-box>
