import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CustomersFacade } from '@app/store/customers';
import { UserFacade } from '@app/store/user';
import { AccountFacade } from '@corelabs/angular-account';
import { delay, filter, Subscription, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    private accountFacade = inject(AccountFacade);
    private userFacade = inject(UserFacade);
    private customersFacade = inject(CustomersFacade);
    private router = inject(Router);

    private subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.accountFacade.loggedOut$
                .pipe(
                    filter((loggedOut) => loggedOut !== null),
                    tap(() => this.clear()),
                    delay(200),
                    tap(() => this.router.navigateByUrl('/auth/sign-in')),
                )
                .subscribe(),
        );
    }

    private clear() {
        this.userFacade.clear();
        this.customersFacade.clear();
        this.accountFacade.clear();
    }
}
