import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchComponent } from '@app/i18n';
import { BoxComponent } from '../../shared/components';
import { MenuItem } from '@app/mobile-menu/models';

@Component({
    selector: 'app-menu-auth',
    templateUrl: './menu-auth.component.html',
    styleUrls: ['./menu-auth.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, BoxComponent, TranslateModule, LanguageSwitchComponent],
})
export class MenuAuthComponent {
    readonly router = inject(Router);

    onItemClick(item: MenuItem) {
        this.router.navigateByUrl(item.route);
    }

    readonly menu = [
        {
            name: 'header.login',
            route: '/auth/sign-in',
        },
    ];
}
