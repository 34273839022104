import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserFacade } from '@app/store/user';
import { Feature } from '@app/shared/types';
import { LanguageSwitchComponent } from '@app/i18n';
import { map } from 'rxjs';
import { BoxComponent, CartSummaryComponent } from '../../shared/components';
import { menu } from '../../configs/menu';
import { MenuItem } from '../../models';
import { UserSwitchService } from '@app/modules/user-switch';
import { Capacitor } from '@capacitor/core';
import { PushNotificationsFacade } from '@app/store/push-notifications';

@Component({
    selector: 'app-menu-user',
    templateUrl: './menu-user.component.html',
    styleUrls: ['./menu-user.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, BoxComponent, TranslateModule, CartSummaryComponent, LanguageSwitchComponent],
})
export class MenuUserComponent {
    private readonly userFacade = inject(UserFacade);
    private readonly router = inject(Router);
    private readonly userSwitchService = inject(UserSwitchService);
    private readonly pushNotificationsFacade = inject(PushNotificationsFacade);
    private replaceUrl = Capacitor.getPlatform() === 'android';

    readonly menuItems$ = this.userFacade.features$.pipe(map((features) => this.getMenuItems(features)));
    readonly userSwitchEnabled$ = this.userSwitchService.enabled$;
    readonly pushNotificationsSupported$ = this.pushNotificationsFacade.state$.pipe(map((state) => state !== 'unsupported'));

    private getMenuItems(features: Feature[]) {
        return menu.filter((column) => column.feature && features.includes(column.feature));
    }

    onItemClick(item: MenuItem) {
        this.router.navigateByUrl(item.route, { replaceUrl: this.replaceUrl });
    }

    onLogoutClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/logout', { skipLocationChange: true });
    }

    onUserSwitchClick(event: MouseEvent) {
        event.preventDefault();
        this.userSwitchService.reset();
        this.router.navigateByUrl('/customers', { replaceUrl: true });
    }

    onProfileClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/user-profile/change-password');
    }

    onPushNotificationsClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/user-profile/push-notifications');
    }
}
