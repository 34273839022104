import { Component, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-mobile-menu-button',
    templateUrl: './mobile-menu-button.component.html',
    styleUrls: ['./mobile-menu-button.component.scss'],
    standalone: true,
})
export class MobileMenuButtonComponent implements OnDestroy {
    private router = inject(Router);
    private subscriptions$ = new Subscription();

    private skipLocationChange = Capacitor.getPlatform() !== 'android';

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onClick() {
        this.router.navigate([{ outlets: { mobile: 'menu' } }], { skipLocationChange: this.skipLocationChange });
    }
}
