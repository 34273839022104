@if (data$ | async; as data) {
    <div class="screen flex flex-col relative">
        <app-header />

        <p
            *ngIf="data.isSalesperson && data.salespersonInfo && data.salespersonInfo.no && data.salespersonInfo.name"
            class="text-eip-green font-semibold text-sm mx-3 | md:mx-5 | 3xl:container 3xl:mx-auto pt-5"
        >
            {{ data.salespersonInfo.no + ' ' + data.salespersonInfo.name }}
        </p>

        <section
            class="grow py-[1.25rem] mx-3 | md:mx-5 md:py-[3.25rem] | 3xl:container 3xl:mx-auto"
            [ngClass]="data.isSalesperson && data.salespersonInfo ? 'md:pt-[1.125rem]' : 'md:pt-[3.25rem]'"
        >
            <app-statuses-container>
                <app-payment-concessions />
                <app-auth-concessions />
                <app-ship />
            </app-statuses-container>

            <router-outlet />
        </section>

        <app-footer />
    </div>
}
