<app-box type="logo">
    <ul>
        @for (item of menu; track $index; let last = $last) {
            <li [class.mb-4]="last">
                <a class="item" (click)="onItemClick(item)">
                    {{ item.name | translate }}
                </a>
            </li>
        }

        <li class="mt-auto">
            <a class="item" href="#" (click)="onProfileClick($event)" translate>btn.profile</a>
        </li>

        <li *ngIf="(pushNotificationsSupported$ | async) === true">
            <a class="item" href="#" (click)="onPushNotificationsClick($event)" translate>btn.push-notifications</a>
        </li>

        <li>
            <a class="item" href="#" (click)="onLogoutClick($event)" translate>btn.logout</a>
        </li>

        <li>
            <div class="px-6 pt-10 pb-10">
                <app-language-switch />
            </div>
        </li>
    </ul>
</app-box>
