import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
})
export class LogoComponent {
    readonly logoSrc = options.logoSrc.dark;
}
