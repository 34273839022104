import { Component, inject } from '@angular/core';
import { IconComponent, PricePipeModule, ShowHide } from '@library';
import { ShoppingCartFacade } from '@app/store/shopping-cart';
import { CommonModule } from '@angular/common';
import { CartModule } from '@app/modules/cart';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrls: ['./cart-summary.component.scss'],
    animations: [ShowHide],
    standalone: true,
    imports: [CommonModule, RouterModule, IconComponent, PricePipeModule, CartModule],
})
export class CartSummaryComponent {
    private readonly router = inject(Router);
    private readonly shoppingCartFacade = inject(ShoppingCartFacade);

    readonly cartPreviewQty$ = this.shoppingCartFacade.cartPreviewQty$;
    readonly cartProcessing$ = this.shoppingCartFacade.cartProcessing$;
    readonly cart$ = this.shoppingCartFacade.cart$;

    onClick() {
        this.router.navigateByUrl('/cart');
    }
}
