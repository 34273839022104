import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { RoleService } from '@app/shared/services';
import { UserFacade } from '@app/store/user';
import { filter, map, Observable, take, tap } from 'rxjs';

export const notAuthenticated: CanMatchFn = (): Observable<boolean> => {
    const userFacade = inject(UserFacade);

    return userFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded === null) {
                userFacade.getUser();
            }
        }),
        filter((loaded) => loaded !== null),
        map((loaded) => loaded !== true),
    );
};

export const isUser: CanMatchFn = (): Observable<boolean> => {
    const roleService = inject(RoleService);

    return roleService.asUser$.pipe(take(1));
};

export const isSalesperson: CanMatchFn = (): Observable<boolean> => {
    const roleService = inject(RoleService);

    return roleService.asSalesperson$.pipe(take(1));
};
