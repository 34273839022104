import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { IconComponent } from '@library';
import { ShellSharedModule, FooterComponent } from '../../shared';
import { LanguageSwitchComponent } from '@app/i18n';
import { RouterModule } from '@angular/router';
import { ShippingAddressesModule } from '@app/modules/shipping-addresses';

import { ShellSalesComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { UserActionsComponent } from './components/header/components/user-actions/user-actions.component';
import { MobileMenuButtonComponent } from '@app/mobile-menu/shared/components';

@NgModule({
    imports: [
        CommonModule,
        IconComponent,
        SharedModule,
        ShellSharedModule,
        FooterComponent,
        RouterModule,
        ShippingAddressesModule,
        LanguageSwitchComponent,
        MobileMenuButtonComponent,
    ],
    declarations: [ShellSalesComponent, HeaderComponent, UserActionsComponent],
})
export class ShellSalesModule {}
