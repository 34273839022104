<header>
    <div>
        <app-logo />
    </div>

    @if (sm$ | async) {
        <div class="ml-auto">
            <app-language-switch />
        </div>

        <div>
            <a class="btn btn--filled" routerLink="/auth/sign-in">{{ 'header.login' | translate }}</a>
        </div>
    } @else {
        <div class="ml-auto">
            <app-mobile-menu-button />
        </div>
    }
</header>
