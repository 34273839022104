import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { bodyLockGuard, bodyUnlockGuard } from '@library';

import { isUser, notAuthenticated, isSalesperson } from './guards/logged-in.guard';
import { ContainerComponent } from './container/container.component';
import { MenuAuthComponent } from './components/menu-auth/menu-auth.component';
import { MenuUserComponent } from './components/menu-user/menu-user.component';
import { MenuSalespersonComponent } from './components/menu-salesperson/menu-salesperson.component';

const routes: Routes = [
    {
        path: 'menu',
        component: ContainerComponent,
        outlet: 'mobile',
        canActivate: [bodyLockGuard()],
        canDeactivate: [bodyUnlockGuard()],
        children: [
            {
                path: '',
                component: MenuAuthComponent,
                canMatch: [notAuthenticated],
                data: {
                    animation: '1',
                },
            },
            {
                path: '',
                component: MenuUserComponent,
                canMatch: [isUser],
                data: {
                    animation: '2',
                },
            },
            {
                path: '',
                component: MenuSalespersonComponent,
                canMatch: [isSalesperson],
                data: {
                    animation: '3',
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MobileMenuRoutingModule {}
