<div [ngClass]="(sm$ | async) ? 'fixed-popup' : 'banner'">
    <div class="flex md:text-start text-center">
        <p class="font-semibold text-sm text-gray-700">
            {{ 'update.version.alert' | translate }}
        </p>
    </div>

    <div class="grid md:grid-cols-2 grid-cols-1 mt-4 items-center">
        @if (sm$ | async) {
            <div class="flex justify-center">
                <app-icon class="icon-primary text-2xl" name="material-symbols:refresh-rounded"></app-icon>
            </div>
        }

        <div class="flex md:justify-end justify-center">
            <button class="btn text-sm btn--filled" (click)="onRefreshClick()">
                {{ 'refresh' | translate }}
            </button>
        </div>
    </div>
</div>
