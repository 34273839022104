import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { footer } from '@footer';
import { IconComponent } from '@library';

@Component({
    selector: 'app-social-icons',
    templateUrl: './social-icons.component.html',
    styleUrls: ['./social-icons.component.scss'],
    standalone: true,
    imports: [CommonModule, IconComponent],
})
export class SocialIconsComponent {
    readonly links = footer.socialLinks;
}
