import { ApplicationRef, ComponentRef, createComponent, inject, Injectable, RendererFactory2 } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, filter, first, interval, tap } from 'rxjs';
import { UpdateVersionComponent } from '../components/update-version.component';

@Injectable({
    providedIn: 'root',
})
export class UpdateVersionService {
    private readonly swUpdate = inject(SwUpdate);
    private readonly appRef = inject(ApplicationRef);
    private readonly rendererFactory2 = inject(RendererFactory2);

    private readonly checkInterval = 5 * 60 * 1000;
    private componentRef?: ComponentRef<UpdateVersionComponent>;
    private renderer = this.rendererFactory2.createRenderer(null, null);

    setListeners() {
        if (!this.swUpdate.isEnabled) {
            return;
        }

        const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));

        concat(appIsStable$, interval(this.checkInterval))
            .pipe(
                tap(() => {
                    this.swUpdate.checkForUpdate();
                }),
            )
            .subscribe();

        this.swUpdate.versionUpdates
            .pipe(
                filter((event) => event.type === 'VERSION_READY'),
                tap(() => this.appendUpdateVersionComponent()),
            )
            .subscribe();
    }

    private appendUpdateVersionComponent() {
        this.componentRef = createComponent(UpdateVersionComponent, {
            environmentInjector: this.appRef.injector,
        });
        this.renderer.appendChild(document.body, this.componentRef.location.nativeElement);
        this.appRef.attachView(this.componentRef.hostView);
    }
}
