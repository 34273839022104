import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchComponent } from '@app/i18n';
import { BoxComponent } from '../../shared/components';
import { MenuItem } from '@app/mobile-menu/models';
import { PushNotificationsFacade } from '@app/store/push-notifications';
import { map } from 'rxjs';

@Component({
    selector: 'app-menu-salesperson',
    templateUrl: './menu-salesperson.component.html',
    styleUrls: ['./menu-salesperson.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, BoxComponent, TranslateModule, LanguageSwitchComponent],
})
export class MenuSalespersonComponent {
    private readonly router = inject(Router);
    private readonly pushNotificationsFacade = inject(PushNotificationsFacade);

    readonly pushNotificationsSupported$ = this.pushNotificationsFacade.state$.pipe(map((state) => state !== 'unsupported'));

    readonly menu = [
        {
            name: 'header.customers',
            route: '/customers',
        },
    ];

    onItemClick(item: MenuItem) {
        this.router.navigateByUrl(item.route);
    }

    onLogoutClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/logout', { skipLocationChange: true });
    }

    onPushNotificationsClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/profile/push-notifications');
    }

    onProfileClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigateByUrl('/profile/change-password');
    }
}
