import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IconComponent, WindowService } from '@library';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-update-version',
    standalone: true,
    imports: [CommonModule, IconComponent, TranslateModule],
    templateUrl: './update-version.component.html',
    styleUrl: './update-version.component.scss',
})
export class UpdateVersionComponent {
    private readonly windowService = inject(WindowService);

    sm$ = this.windowService.sm$;

    onRefreshClick() {
        window.location.reload();
    }
}
