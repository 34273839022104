<footer class="flex flex-col bg-teriary text-white w-full py-10 md:px-20 px-6 bottom-0 relative gap-10">
    @if (footerOverride) {
        <app-footer-adresses />
    } @else {
        <app-footer-default />
    }

    <div class="absolute bottom-0 right-0">
        <app-version />
    </div>
</footer>
